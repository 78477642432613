export enum APP_NAVIGATION_LINKS {
  HOME = 'home',
  CLIENTS = 'clients',
  CLIENT_DETAILS = CLIENTS + '/details',
  ClIENT_EMPLOYEE_ADD = 'employee/add',
  CLIENT_EMPLOYEE_EDIT = 'employee/edit/',
  RESOURCES = 'resources',
  RESOURCE_DETAILS = RESOURCES + '/details',
  RESOURCE_RECIPE = RESOURCE_DETAILS + '/recipe',
  WAREHOUSE = 'warehouse',
  INVENTORY_LEVELS = WAREHOUSE + '/inventory-levels',
  WAREHOUSE_DOCUMENTS = WAREHOUSE + '/warehouse-documents',
  WAREHOUSE_DOCUMENT_DETAILS = WAREHOUSE_DOCUMENTS + '/details',
  LOCATION_HISTORIES = WAREHOUSE + '/location-histories',
  LOCATIONS = WAREHOUSE + '/locations',
  LOCATION_ADD = LOCATIONS + '/add',
  LOCATION_EDIT = LOCATIONS + '/edit',
  LOCATION_DETAILS = LOCATIONS + '/details',
  WAREHOUSES = WAREHOUSE + '/warehouses',
  LOCATION_ITEMS = WAREHOUSE + '/location-items',
  STOCKTAKING = WAREHOUSE + '/stocktaking',
  STOCKTAKING_DETAILS = STOCKTAKING + '/details',
  LABELS = WAREHOUSE + '/labels',
  BATCH_NUMBERS = WAREHOUSE + '/batch-numbers',
  PRODUCTION = 'production',
  CALENDAR = PRODUCTION + '/production-orders/calendar',
  PRODUCTION_ORDERS = PRODUCTION + '/production-orders',
  PRODUCTION_ORDERS_DETAIL = PRODUCTION + '/production-orders/details',
  PRODUCTION_LINES = PRODUCTION + '/production-lines',
  PRODUCTION_LINE_DETAILS = PRODUCTION_LINES + '/details',
  PRODUCTION_STANDS = PRODUCTION + '/production-stands',
  PRODUCTION_STANDS_DETAILS = PRODUCTION_STANDS + '/details',
  PRODUCTION_UNITS = PRODUCTION + '/production-units',
  REPORTS = PRODUCTION + '/production-reports',
  ORDERS_BASE = 'orders',
  ORDERS = ORDERS_BASE + '/orders',
  ORDER_DETAILS = ORDERS + '/details',
  ADMINISTRATION = 'administration',
  USERS = ADMINISTRATION + '/users',
  USER_DETAILS = USERS + '/details',
  SYSTEM_LOGS = ADMINISTRATION + '/system-logs',
  SYSTEM_LOGS_DETAILS = SYSTEM_LOGS + '/details',
  USERS_ACTIVITY = ADMINISTRATION + '/users-activities',
  PRINTERS = ADMINISTRATION + '/printers',
  DICTIONARY_DATA = '/dictionary-data',
  PRODUCTION_ORDERS_STATUSES = ADMINISTRATION +
    DICTIONARY_DATA +
    '/production-orders-statuses',
  ORDERS_STATUSES = ADMINISTRATION + DICTIONARY_DATA + '/order-statuses',
  ORDER_TYPES = ADMINISTRATION + DICTIONARY_DATA + '/order-types',
  CLIENTS_GROUPS = ADMINISTRATION + DICTIONARY_DATA + '/clients-groups',
  COLLECTIVE_PACKAGING = ADMINISTRATION +
    DICTIONARY_DATA +
    '/collective-packaging',
  ITEM_CATEGORIES = ADMINISTRATION + DICTIONARY_DATA + '/item-categories',
  ITEM_GROUPS = ADMINISTRATION + DICTIONARY_DATA + '/item-groups',
  PACKAGING_GROUPS = ADMINISTRATION + DICTIONARY_DATA + '/packaging-groups',
  PACKAGING_TYPES = ADMINISTRATION + DICTIONARY_DATA + '/packaging-types',
  PACKAGING = ADMINISTRATION + DICTIONARY_DATA + '/packaging',
  PHOTO_DOCUMENTATION = WAREHOUSE + '/photo-documentation',
  PRODUCTION_REPORTS = PRODUCTION + '/production-reports',
  PRODUCTION_REPORT_EDIT = PRODUCTION_REPORTS + '/edit'
}
